import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import SEO from '../components/SEO'

const Wrapper = styled.div`
  max-width: 80ch;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1280px) {
    max-width: 46rem;
    padding: 1rem 0rem;
  }
  padding: 2.625rem 1.3125rem;
  .image-wrapper {
    display: flex;
    justify-content: center;
    left: 50%;
    margin-left: -45vw;
    margin-right: -45vw;
    position: relative;
    right: 50%;
    width: 90vw;
    flex-direction: column;
    align-items: center;
  }
`

const Information = styled.div`
  font-family: 'Inconsolata', monospace;
  font-weight: 200;
  font-size: 14px;
  color: #a1a1a1;
  margin: 25px 0px;
`

const DateAndNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const NameLink = styled(Link)`
  color: #a1a1a1;
`

const Article = styled.article`
  font-weight: 300;

  section {
    margin: 18px 0;
    line-height: 1.7;
  }

  p, li {
    font-family: 'Merriweather', serif;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }

  li > p {
    margin: 0;
  }

  li:not(li li) {
    margin: 6px 0;
  }

  code {
    font-family: Courier, monospace;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
`

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Wrapper>
        <Article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <Information>
              <DateAndNameWrapper>
                <div>{post.frontmatter.date}</div>
                <NameLink to="/blog">
                  by {data.site.siteMetadata?.author?.name}
                </NameLink>
              </DateAndNameWrapper>
              <div>{post.timeToRead} min. read</div>
            </Information>
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </Article>
      </Wrapper>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
      timeToRead
    }
  }
`
